.styled-input::-webkit-input-placeholder {
  font-style: italic;
  font-size: 0.9em;
}
.styled-input:-ms-input-placeholder {
  font-style: italic;
  font-size: 0.9em;
}
.styled-input::placeholder {
  font-style: italic;
  font-size: 0.9em;
}

.input-title {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
}

.input-score {
  margin: 0;
  margin-left: 12px;
  padding: 10px 20px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
