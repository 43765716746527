.navbar .collapsing .navbar-toggler{
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i{
  margin-right: 4px;
}


.apps-area {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 50px;
}