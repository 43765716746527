.custom-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.custom-select {
  display: block;
  width: 80px;
  height: 40px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-left: 10px;
}

.input-text-area {
  display: block;
  width: 100%;
  min-height: 100px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.remove-button {
  border-radius: 20px !important;
  width: 30px !important;
  height: 21px !important;
  padding: 0 !important;
  font-size: 15px !important;
  font-weight: bold !important;
  align-self: center !important;
}

.add-button {
  height: 25px !important;
  font-size: 12px !important;
  padding: 0 5px;
}

.condition-btn {
  margin-bottom: 15px;
}

.custom-line {
  :hover {
    background-color: #cbd5e1;
  }
}

.basic-single {
  font-size: 14px;
}

.custom-head th {
  font-size: 12px !important;
  font-weight: bold;
}

.custom-tr:hover {
  background-color: #5461821a;
  filter: brightness(80%);
}

.toggle-slide {
  border: 1px solid rgb(178, 202, 223);
  height: 15px;
  width: 40px;
  border-radius: 10px;
  background-color: aliceblue;
  position: relative;
  transition: all 200ms;
}

.switch {
  height: 15px;
  width: 15px;
  background-color: rgb(178, 202, 223);
  border-radius: 999px;
  position: absolute;
  top: 0px;
  left: 2px;
  transition: ease-in 200ms;
}

.slide {
  transition: ease-in 200ms;
  position: absolute;
  left: 65%;
}

.fav-content img {
  width: 100%;
  height: auto;
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 630px;
}

.chat-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  margin-bottom: 10px;
}

.chat-body::-webkit-scrollbar {
  width: 12px;
}

.chat-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-body::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 10px;
}

.chat-body::-webkit-scrollbar-thumb:hover {
  background: #f0f0f0;
}

.chat-message {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #007bff;
}

.own-message {
  align-self: flex-end;
  background-color: #003d6c;
  width: 60%;
}

.other-message {
  align-self: flex-start;
  background-color: #006b9a;
  width: 60%;
}

.message-header {
  font-size: 0.9em;
  margin-bottom: 5px;
  color: #ffffff;
}

.lifecycle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 13px;
  margin-bottom: 10px;
}

.lifecycle-stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: #006b9a;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.lifecycle-stage.current {
  background-color: #003d6c;
  color: white;
}

.stage-name {
  font-weight: bold;
}

.stage-date {
  font-size: 0.8em;
  color: #666;
}

.stage-date.current {
  font-size: 0.8em;
  color: white;
}

.current.highlighted-stage {
  background-color: #006b9a;
}

.checked-table td,
.checked-table th {
  text-align: center;
}

.hover-remove:hover {
  background-color: rgb(255, 189, 173) !important;
  color: rgb(222, 53, 11) !important;
}
