.tree-select {
  position: relative;
  background-color: #fff;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  font-size: 14px;
  color: hsl(0, 0%, 20%);
}

.tree-select-input {
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-icon {
  margin-left: 10px;
  transition: transform 0.2s ease;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.tree-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
}

.tree-node {
  padding: 8px 12px;
  cursor: pointer;
}

.tree-node-label:hover {
  background-color: #f0f0f0;
  font-weight: bold;
}

.tree-node-children {
  margin-left: 20px;
  border-left: 1px dashed #ccc;
  padding-left: 10px;
}

.tree-select-search {
  margin: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  width: 96%;
  border-color: #ccc;
}
