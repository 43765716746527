.styled-input::-webkit-input-placeholder {
  font-style: italic;
  font-size: 0.9em;
}
.styled-input:-ms-input-placeholder {
  font-style: italic;
  font-size: 0.9em;
}
.styled-input::placeholder {
  font-style: italic;
  font-size: 0.9em;
}

.input-textarea {
  display: block;
  width: 100%;
  height: 100px;
  resize: none;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  font-style: normal;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
}

.box {
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.score-group {
  display: flex;
  flex-direction: column;
  border: 1px solid #7393B3;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  gap: 8px;
  margin-top: 32px;
}

.btn-accordion {
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.icon-warning {
  width: 36px;
  margin: 0;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: #E9E9E9;
}
