.styled-scroll::-webkit-scrollbar {
  height: 10px;
  background: transparent;
  widtH: 8px;
  border-radius: 4px;
}

.styled-scroll::-webkit-scrollbar-track {
  background: transparent;
  widtH: 5px;
}

.styled-scroll::-webkit-scrollbar-button {
  display: none;
}

.styled-scroll::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 4px;
}

.styled-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}